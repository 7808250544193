<template>
  <default-layout>
    <div class="pa-4">
      <div class="add--listing-no-login">
        <img
          src="https://rentfixs3.s3.ap-southeast-1.amazonaws.com/public/static/img/static/banner-add-listing.jpg"
        />
        <div class="info--text">
          Punya properti kosong? (Rumah, Apartemen, Ruko, Kios, Gudang, Ruang kantor) mau di
          sewakan? Daftarkan langsung ke Rentfix.com dan dapatkan cuan setiap bulannya!
          <br /><br />
          2.400 unit Properti sudah bergabung dan mendapatkan keuntungan sebagai berikut:
          <ul>
            <li>Pasang iklan / listing gratis</li>
            <li>Membantu properti Anda cepat tersewa</li>
            <li>Tersedia fitur Perjanjian Digital</li>
          </ul>
          Anda bisa langsung daftarkan iklan/listing unit properti sewa Anda dengan cara:
          <ul>
            <li>Registrasi akun Anda pada website / aplikasi rentfix.com</li>
            <li>Klik tombol daftarkan properti</li>
          </ul>
        </div>
      </div>
      <login
        formType="add-listing"
        :afterAction="afterAction"
        @switchAuth="switchAuth"
        v-if="authType === 'login'"
      />
      <register
        formType="add-listing"
        :afterAction="afterAction"
        @switchAuth="switchAuth"
        v-if="authType === 'register'"
      />
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Login from '@/components/auth/login';
import Register from '@/components/auth/register';
import { mapState } from 'vuex';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    Login,
    Register,
  },
  data: () => ({
    authType: 'login',
  }),
  computed: {
    ...mapState({}),
  },
  methods: {
    afterAction() {
      this.$router.replace('/listing/add');
    },
    switchAuth() {
      if (this.authType === 'login') {
        this.authType = 'register';
      } else {
        this.authType = 'login';
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.add--listing-no-login {
  font-size: $font-xxl;
  text-align: center;
  margin-bottom: 36px;
  @media #{$phones, $tablets} {
    margin-bottom: 24px;
  }
  img {
    height: 200px;
    max-width: 100%;
    object-fit: contain;
    @media #{$phones, $tablets} {
      height: auto;
    }
  }
  .info--text {
    max-width: 600px;
    font-size: $font-ml;
    text-align: left;
    margin: 16px auto 0 auto;
  }
}
</style>
